import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'

var firebaseConfig = {
  apiKey: "AIzaSyC1rDiT4cjnsMui2qwDdsIxF4tPNQb6qG8",
  authDomain: "tandapp-fab2e.firebaseapp.com",
  projectId: "tandapp-fab2e",
  storageBucket: "tandapp-fab2e.appspot.com",
  messagingSenderId: "182631893582",
  appId: "1:182631893582:web:b115ee149cabb39d89d11f"
};

let instance;

export default function getFirebase() {
  if (typeof window !== "undefined") {
    if (instance) return instance;
    instance = firebase.initializeApp(firebaseConfig);
    return instance;
  }

  return null;
}
