/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react';
import { AuthProvider } from "./src/contexts/AuthContext" // Убедитесь, что путь корректен

export const wrapRootElement = ({ element }) => (
  <AuthProvider>
    {element}
  </AuthProvider>
);
