// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import getFirebase from "../components/scripts/firebaseSrc"

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const firebase = getFirebase();

    const unsubscribe = firebase?.auth().onAuthStateChanged(authUser => {
      setCurrentUser(authUser ? authUser.uid : null);
    });

    // Отписка от прослушивания при размонтировании компонента
    return () => unsubscribe?.();
  }, []);

  return (
    <AuthContext.Provider value={currentUser}>
      {children}
    </AuthContext.Provider>
  );
};

// Хук для использования контекста авторизации в компонентах
const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
